@import "minima";

$text-color: #222 !default;
$header-bg: #fc9920;
$header-bg-border: darken($header-bg, 9);

$subtitle-color: #222;
$link-color-visited: #222;

$img-border: none;

.heading,
.sans {
  font-family: Lato, "PT Sans", "Helvetica Neue", Arial, sans-serif;
}

body {
  line-height: 1.7rem;
  color: $text-color;
  @extend .sans;
}

header.site-header {
  background-color: $header-bg;
  border-top-color: $header-bg-border;
}

a.site-title,
a.page-link,
a.site-title:visited,
a.page-link:visited {
  color: white;
}

a img {
  padding-right: 1rem;
}

img a {
  border: none;
}

body {
  > header {
    padding-top: 0;
    padding-bottom: 0;
    > h1 {
      padding: 0;
      margin: 0;
    }
    > h2 {
      color: #000;
    }
  }
}

.post-content {
  margin-bottom: $spacing-unit;

  p {
    @include relative-font-size(1.1);
  }

  h1 {
    @include relative-font-size(2.25);

    @include media-query($on-laptop) {
      @include relative-font-size(2);
    }
  }

  p.blog_post_date {
    margin-top: 3rem;
  }

  p.blog_post_title {
    @include relative-font-size(1.5);
    margin-bottom: 0;
  }

  div.screenshots {
    display: flex;
    gap: 16px;
    justify-content: center;
    margin: 2rem 0;
  }
}

h1 {
  padding-top: 1.5rem;
}

h2 {
  clear: both;
  padding-top: 1rem;
}

div.project {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 6rem;
  margin-top: 3rem;

  .project_details {
    display: flex;
    flex-direction: row;

    > img {
      min-width: 30%;
      max-width: 30%;
      width: auto;
      height: 100%;
    }

    .no-image {
      min-width: 30%;
    }

    div.description {
      margin-left: 2rem;
      display: flex;
      flex-direction: column;

      > h3 {
        font-weight: bold;
      }

      > p {
        margin-top: 0;
      }
    }
    p.no_margin {
      margin-bottom: 0;
    }

    div.no_left_margin {
      margin-left: 0;
    }

    div.platforms {
      > ul {
        margin-bottom: 0;
      }
    }
  }
}

div.app-listing {
  display: flex;
  flex-direction: row;
}

@media only screen and (max-width: 600px) {
  a.page-link {
    color: black !important;
  }

  div.project_details {
    flex-direction: column !important;
  }

  div.description {
    margin-left: 0 !important;
  }

  div.screenshots {
    flex-direction: column !important;
    align-items: center;
  }
}
